import React from 'react';
import {Link} from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container relative flex flex-col lg:flex-row mt-24 px-6 lg:px-12">
      <div className="flex flex-col w-full lg:w-3/6 lg:pr-12">
        <p className="text-gray mb-2">ERROR</p>
        <h1 className="text-5xl leading-tight font-semibold">NOT FOUND</h1>
        <p className="text-gray mt-2 leading-relaxed">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
        <div>
          <Link
            to="/"
            className="mt-12 inline-block bg-primary font-medium text-white py-3 px-5 text-lg outline-none focus:shadow-outline"
          >
            Go to Home
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
